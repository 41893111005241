import React from 'react';
import styled, { css } from 'styled-components';
import theme from '../../../theme/theme';

const StyledWrapper = styled.div`
  ${({ $initialDisplay, $breakpoints }) => css`
    display: ${$initialDisplay};

    ${Object.keys($breakpoints)
      .map(
        (breakpoint) => `
            @media screen and (min-width: ${theme.breakpoints[breakpoint]}) {
              display: ${$breakpoints[breakpoint] || 'none'};
            }
          `,
      )
      .join('')}
  `}
`;
export default function BreakpointBox({
  initialDisplay = 'unset',
  breakpoints,
  children,
  className = {},
  ...props
}) {
  return (
    <StyledWrapper
      className={className}
      $initialDisplay={initialDisplay}
      $breakpoints={breakpoints}
      {...props}
    >
      {children}
    </StyledWrapper>
  );
}
